import React, { useState} from 'react';
import data from '../../data/data.json';
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Link } from 'react-router-dom';
import FavoriteIcon from "@mui/icons-material/Favorite";

const HomeCourse = () => {
    const [filter, setFilter] = useState("*"); 

    const handleFilterClick = (filterValue) => {
        setFilter(filterValue);
    };

    const filteredItems = filter === "*" ? data : data.filter(item => item.category === filter);

    const shuffledArray = filteredItems.sort(() => Math.random() - 0.5);
    const randomPosts = shuffledArray.slice(0, 6);

    return (
        <div className="gridarea gridarea__2">
            <div className="container">
                <div className="row grid__row">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12" data-aos="fade-up">
                        <div className="section__title__2">
                            <div className="section__title__button">
                                <div className="default__small__button">Course List</div>
                            </div>
                            <div className="section__title__heading__2 section__title__heading__3 heading__fontsize__2">
                                <h2>Perfect Online Course Your Carrer</h2>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12" data-aos="fade-up">
                        <div className="gridfilter_nav grid__filter__2 gridFilter">
                            <button className={filter === "*" ? "active" : ""} onClick={() => handleFilterClick("*")}>See All</button>
                            <button className={filter === "FrontEnd" ? "active" : ""} onClick={() => handleFilterClick("FrontEnd")}>FrontEnd</button>
                            <button className={filter === "BackEnd" ? "active" : ""} onClick={() => handleFilterClick("BackEnd")}>BackEnd</button>
                            <button className={filter === "UX/UI Design" ? "active" : ""} onClick={() => handleFilterClick("UX/UI Design")}>UX/UI Design</button>
                            <button className={filter === "Data & Tech" ? "active" : ""} onClick={() => handleFilterClick("Data & Tech")}>Data & Tech</button>
                        </div>
                    </div>
                </div>

                <div className="row grid" data-aos="fade-up">
                <Grid container spacing={2}>
                    {randomPosts.map((e, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                        <div className={`  ${e.category}`}
                        data-aos="fade-up">
                            <div className="gridarea__wraper">
                                <div className="gridarea__img">
                                    <Link to={`/course_detailed/${e.id}`}><img loading="lazy" src={e.img} alt="grid"/></Link>
                                    <div className="gridarea__small__button">
                                        <div className="grid__badge">{e.category}</div>
                                    </div>
                                    <div className="gridarea__small__icon">
                                        <a href="#/"><i className="icofont-heart-alt"><FavoriteIcon/></i></a>
                                    </div>
                                </div>
                                <div className="gridarea__content">
                                    <div className="gridarea__list">
                                        <ul>
                                            <li>
                                                <i className="icofont-book-alt"></i> {e.lectures}
                                            </li>
                                            <li>
                                                <i className="icofont-clock-time"></i>{e.duration} Hrs
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="gridarea__heading">
                                        <h3><Link to={`/course_detailed/${e.id}`}>{e.title}</Link></h3>
                                    </div>
                                    <div className="gridarea__price">
                                        RM{e.price}{" "}
                                        {e.prevprice && <del>RM{e.prevprice}</del>}
                                        <span> <del className="del__2">Free</del></span>
                                    </div>
                                    <div className="gridarea__bottom">
                                        <a href="instructor-details.html">
                                            <div className="gridarea__small__img">
                                                <img loading="lazy" src="assets/img/grid_small_1.jpg" alt="grid"/>
                                                <div className="gridarea__small__content">
                                                    <h6>{e.author[0].name}</h6>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Grid>
                    ))}
                    </Grid>
                    <div className="col-lg-12" style={{margin: "50px auto", display: "flex", justifyContent: "center"}}>
                    <div className="border-first-button scroll-to-section">
                      <a href="/allcourses">More Courses</a>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    );
};

export default HomeCourse;
