import React from 'react';
import './Platform.scss';
import Data from '../../Url'
import {Link} from 'react-router-dom'

const Platform = () => {


  return (
    <div>
      <div
        className="col-lg-12"
        style={{ textAlign: "center" }}
      >
        <div
          className="section-heading animate__animated animate__fadeInDown animate__delay-1s"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
        >
          <h6>Platforms</h6>
          <h4>
            Latest Platform <em>We</em> Work on{" "}
          </h4>
          <div className="line-dec" style={{ margin: "0 auto" }}></div>
        </div>
      </div>
      <div className="col-lg-12">
        <fieldset className="checkbox-group">
          {Data?.map((data, index) => (
            <div className="checkbox" key={index}>
				<Link to={"/allcourses"}>
				
              <label className="checkbox-wrapper">
                <span className="checkbox-tile">
                  <span className="checkbox-icon">
                    <img src={data.img} alt="" style={{ width: "40px" }} />
                  </span>
                  <span className="checkbox-label">{data.title}</span>
                </span>
              </label>
			  </Link>
            </div>
          ))}
        </fieldset>
		<div className="col-lg-12" style={{margin: "50px auto", display: "flex", justifyContent: "center"}}>
                    <div className="border-first-button scroll-to-section">
                      <Link to="/allcourses">Load More</Link>
                    </div>
                  </div>
      </div>

    </div>
  );
}

export default Platform
