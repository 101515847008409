const Data = [
    {
        img: "assets/images/node-js.png",
        title: "Node Js"
    },
    {
        img: "assets/images/atom.png",
        title: "React Js"
    },
    {
        img: "assets/images/mysql.png",
        title: "MySQL"
    },
    {
        img: "assets/images/python.png",
        title: "Python"
    },
    {
        img: "assets/images/web.png",
        title: "Asp.NET"
    },
    {
        img: "assets/images/java.png",
        title: "Java"
    },
    {
        img: "assets/images/c-.png",
        title: "C++"
    },
    {
        img: "assets/images/c-sharp.png",
        title: "C#"
    },
    {
        img: "assets/images/wordpress.png",
        title: "WordPress"
    },
    {
        img: "assets/images/php.png",
        title: "PHP"
    }
]

export default Data;
