import React, { useState } from "react";
import servicesData from "../../../data/data.json";
import Dialog from '@mui/material/Dialog';

import "../services/Service.scss";

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';



const TrainingCourses = () => {
  const [filter, setFilter] = useState("Beginner");

  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const togglePopup = () => {
    setIsOpen(true);
  };

  const openDetail = (service) => {
    setSelectedItem(service);
    togglePopup();
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClick = (filter) => {
    setFilter(filter);
  };


  return (
    <div className="gridarea" style={{ margin: "50px 0" }} >
      <div className="container">
        <div className="row">
          <div className="section__title text-center" data-aos="fade-up">
            <div className="section__title__button">
              <div className="default__small__button">Training Courses</div>
            </div>
            <div className="section__title__heading heading__underline">
              <h2>
                We help entrepreneurs achieving their goal faster with our
                <br /> Creativity and Excellences
              </h2>
            </div>
          </div>
        </div>

        <div className="row" data-aos="fade-up">
          <div >
            <div className="gridfilter_nav grid__filter gridFilter">
              <button
                className={filter === "Beginner" ? "active" : ""}
                onClick={() => handleClick("Beginner")}
              >
                Beginner
              </button>
              <button
                className={filter === "Intermediate" ? "active" : ""}
                onClick={() => handleClick("Intermediate")}
              >
                Intermediate
              </button>
              <button
                className={filter === "Advanced" ? "active" : ""}
                onClick={() => handleClick("Advanced")}
              >
                Advanced
              </button>
            </div>
          </div>
        </div>

        {/* Repeat the following structure for each grid item */}
        <div className="row grid" data-aos="fade-up">
        {/* Grid Item */}
        {servicesData?.map(
          (service, index) =>
            service.level === filter &&
              <div
                key={index}
                className="col-xl-4 col-lg-6 col-sm-12 grid"
                onClick={() => openDetail(service)}
              >
                <div className="single__service" onClick={handleClose}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <div className="service__img">
                      <div className="service__bg__img" >
                        <img src={service.img} alt="" style={{borderRadius: "30px"}}/>
                      </div>
                    </div>
                    <div className="service__content">
                      <h3>{service.category}</h3>
                    </div>
                  </div>
                </div>
              </div>
        )}
      </div>
        {isOpen && (
        <Dialog  open={isOpen}
        onClose={handleClose}
        >
          <div className="content" style={{padding: "50px"}}>
            <div className="container">
              <div className="dots">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>

              <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
  <CloseIcon />
</IconButton>
              <div className="title" style={{textAlign: "center"}}>
                <h1>{selectedItem.category}</h1>
              </div>

              <img src={selectedItem.img} alt={selectedItem.lang} style={{
                borderRadius: "50px"
              }}/>

              <div className="description" style={{textAlign: "center", padding: "25px"}}>
                <p>{selectedItem.subtext}</p>
              </div>
            </div>
          </div>
        </Dialog>
      )}
      </div>
    </div>
  );
};

export default TrainingCourses;
