import React from "react";
import "./Partners.scss";

const Partners = () => {
  return (
    <div style={{padding: "40px 0"}}>
      <div
        className="col-lg-12"
        style={{ textAlign: "center", margin: "50px 0" }}
      >
        <div
          className="section-heading animate__animated animate__fadeInDown animate__delay-1s"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
        >
          <h6>Clients</h6>
          <h4>
		  Here is our Excellence & Our valued <em>Clients</em>{" "}
          </h4>
          <div className="line-dec" style={{ margin: "0 auto" }}></div>
        </div>
      </div>

      <div className="col-lg-12">
        <div className="slider">
          <div className="slide-track">
            <div className="slide" style={{padding: "0 20px"}}> 
              <img
                src="assets/images/LUCone.jpeg"
                height="60"
                width="250"
                alt=""
              />
            </div>
            <div className="slide"  style={{padding: "0 20px"}}>
              <img
                src="assets/images/LUCtwo.jpeg"
                height="60"
                width="250"
                alt=""
              />
            </div>
            <div className="slide"  style={{padding: "0 20px"}}>
              <img
                src="assets/images/LUCthree.jpeg"
                height="60"
                width="250"
                alt=""
              />
            </div>
            <div className="slide"  style={{padding: "0 20px"}}>
              <img
                src="assets/images/LUCfour.jpeg"
                height="60"
                width="250"
                alt=""
              />
            </div>
            <div className="slide"  style={{padding: "0 20px"}}>
              <img
                src="assets/images/LUCfive.jpeg"
                height="60"
                width="250"
                alt=""
              />
            </div>
            <div className="slide"  style={{padding: "0 20px"}}>
              <img
                src="assets/images/LUCsix.jpeg"
                height="60"
                width="250"
                alt=""
              />
            </div>
            <div className="slide"  style={{padding: "0 20px"}}>
              <img
                src="assets/images/LUCseven.jpeg"
                height="60"
                width="250"
                alt=""
              />
            </div>
            <div className="slide"  style={{padding: "0 20px"}}>
              <img
                src="assets/images/LUCeight.jpeg"
                height="60"
                width="250"
                alt=""
              />
            </div>
            <div className="slide"  style={{padding: "0 20px"}}>
              <img
                src="assets/images/LUCnine.png"
                height="60"
                width="250"
                alt=""
              />
            </div>
            <div className="slide"  style={{padding: "0 20px"}}>
              <img
                src="assets/images/LUCten.jpeg"
                height="60"
                width="250"
                alt=""
              />
            </div>
            <div className="slide"  style={{padding: "0 20px"}}>
              <img
                src="assets/images/LUCeleven.jpeg"
                height="60"
                width="250"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
