import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import data from "../../data/data.json";

import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import CheckIcon from '@mui/icons-material/Check';

const Course = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("projects__two");
  const [openCollapse, setOpenCollapse] = useState(null);

  // Функция для изменения состояния открытого/закрытого состояния элемента аккордеона
  const toggleCollapse = (index) => {
    setOpenCollapse((prevOpenCollapse) =>
      prevOpenCollapse === index ? null : index
    );
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const shuffledArray = data.sort(() => Math.random() - 0.5);
  const randomPosts = shuffledArray.slice(0, 4);

  const course = data.find((course) => course.id === parseInt(id));

  if (!course) {
    return <div>Course not found</div>;
  }

  const accordionData = [
    {
      title: "Intro Course content",
      duration: "02hr 35min",
      content: [
        {
          type: "Video :",
          title: "Lorem ipsum dolor sit amet.",
          duration: "22 minutes",
          link: "lesson.html",
          lockIcon: "icofont-eye",
        },
        // Другие элементы содержимого
      ],
    },
    {
      title: "Intro Course content",
      duration: "02hr 35min",
      content: [
        {
          type: "Video :",
          title: "Lorem ipsum dolor sit amet.",
          duration: "22 minutes",
          link: "lesson.html",
          lockIcon: "icofont-eye",
        },
        // Другие элементы содержимого
      ],
    },
    {
      title: "Intro Course content",
      duration: "02hr 35min",
      content: [
        {
          type: "Video :",
          title: "Lorem ipsum dolor sit amet.",
          duration: "22 minutes",
          link: "lesson.html",
          lockIcon: "icofont-eye",
        },
        // Другие элементы содержимого
      ],
    },
    // Другие элементы аккордеона
  ];

  return (
    <div className="blogarea__2 sp_top_100 sp_bottom_100" style={{margin: "50px 0"}}>
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-8">
            <div
              className="blogarae__img__2 course__details__img__2"
              data-aos="fade-up"
            >
              <img loading="lazy" src={course.img} alt="blog" />
            </div>

            <div className="blog__details__content__wraper">
              <div className="course__button__wraper" data-aos="fade-up">
                <div className="course__button">
                  <a href="#/">Featured</a>
                  <a className="course__2" href="#/">
                    {course.category}
                  </a>
                </div>
                <div className="course__date">
                </div>
              </div>
              <div className="course__details__heading" data-aos="fade-up">
                <h3>{course.title}</h3>
              </div>
              <div className="course__details__price" data-aos="fade-up">
                <ul>
                  <li>
                    <div className="course__price">
                      RM{course.price}{" "}
                      {course.prevprice && <del>RM{course.prevprice}</del>}
                    </div>
                  </li>
                  <li>
                    <div className="course__details__date">
                      <i className="icofont-book-alt"></i> {course.lectures}{" "}
                      Lesson
                    </div>
                  </li>
                </ul>
              </div>
              <div className="course__details__paragraph" data-aos="fade-up">
                <p>{course.subtext}</p>
              </div>

              <h4 className="sidebar__title" data-aos="fade-up">
                Course Details
              </h4>
              <div className="course__details__wraper" data-aos="fade-up">
                <ul>
                  <li>
                    Instructor : <span> {course.author[0].name}</span>
                  </li>
                  <li>
                    Lectures : <span> {course.lectures}</span>
                  </li>
                  <li>
                    Duration : <span> {course.duration} hours</span>
                  </li>
                  <li>
                    Enrolled : <span> 2 students</span>
                  </li>
                  <li>
                    Total : <span> 222 students</span>
                  </li>
                </ul>
                <ul>
                  <li>
                    Course level : <span> {course.level}</span>
                  </li>
                  <li>
                    Language : <span>{course.language}</span>
                  </li>
                  <li>
                    Price Discount : <span>-20%</span>
                  </li>
                  <li>
                    Regular Price : <span>RM{course.price}</span>
                  </li>
                  <li>
                    Course Status : <span>Available</span>
                  </li>
                </ul>
              </div>

              <div className="course__details__tab__wrapper" data-aos="fade-up">
                <div className="row">
                  <div className="col-xl-12">
                    <ul
                      className="nav course__tap__wrap"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className={`single__tab__link ${
                            activeTab === "projects__two" ? "active" : ""
                          }`}
                          onClick={() => handleTabClick("projects__two")}
                          type="button"
                        >
                          <i className="icofont-book-alt"></i>Curriculum
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`single__tab__link ${
                            activeTab === "projects__one" ? "active" : ""
                          }`}
                          onClick={() => handleTabClick("projects__one")}
                          type="button"
                        >
                          <i className="icofont-paper"></i>Description
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`single__tab__link ${
                            activeTab === "projects__four" ? "active" : ""
                          }`}
                          onClick={() => handleTabClick("projects__four")}
                          type="button"
                        >
                          <i className="icofont-teacher"></i>Instructor
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="tab-content tab__content__wrapper"
                  id="myTabContent"
                >

                    <div className={`tab-pane fade ${
                        activeTab === "projects__two" ? "active show" : ""
                      }`}
                      id="projects__two_tab"
                      role="tabpanel"
                      aria-labelledby="projects__two"
                    >
                      <div
                        className="accordion content__cirriculum__wrap"
                        id="accordionExample"
                      >
                        {accordionData.map((item, index) => (
                          <div className="accordion-item" key={index}>
                            <h2
                              className="accordion-header"
                              id={`heading${index}`}
                            >
                              <button
                                className={`accordion-button ${
                                  openCollapse === index ? "" : "collapsed"
                                }`}
                                type="button"
                                onClick={() => toggleCollapse(index)}
                                aria-expanded={
                                  openCollapse === index ? "true" : "false"
                                }
                                aria-controls={`collapse${index}`}
                              >
                                {item.title} <span>{item.duration}</span>
                              </button>
                            </h2>
                            <div
                              id={`collapse${index}`}
                              className={`accordion-collapse collapse ${
                                openCollapse === index ? "show" : ""
                              }`}
                              aria-labelledby={`heading${index}`}
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                {/* Содержимое для каждого элемента аккордеона */}
                                {item.content.map(
                                  (contentItem, contentIndex) => (
                                    <div
                                      className="scc__wrap"
                                      key={contentIndex}
                                    >
                                      <div className="scc__info">
                                        <i className={contentItem.icon}></i>
                                        <h5>
                                          <span>{contentItem.type}</span>{" "}
                                          {contentItem.title}
                                        </h5>
                                      </div>
                                      <div className="scc__meta">
                                        <span>
                                          <i className="icofont-clock-time"></i>{" "}
                                          {contentItem.duration}
                                        </span>
                                        <a href={contentItem.link}>
                                          <span>
                                            <i
                                              className={contentItem.lockIcon}
                                            ></i>
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                  <div className={`tab-pane fade ${
                      activeTab === "projects__one" ? "active show" : ""
                    }`}
                    id="projects__one_tab"
                    role="tabpanel"
                    aria-labelledby="projects__one"
                  >
                    <div className="experence__heading">
                      <h5> Experience Description</h5>
                    </div>
                    <div
                      className="experence__description"
                      style={{ color: "black" }}
                    >
                     {course.descr}
                    </div>
                  </div>

                  <div className={`tab-pane fade ${
                      activeTab === "projects__four" ? "active show" : ""
                    }`}
                    id="projects__four_tab"
                    role="tabpanel"
                    aria-labelledby="projects__four"
                  >
                    <div className="blogsidebar__content__wraper__2 tab__instructor">
                      <div className="blogsidebar__content__inner__2">
                        <div className="blogsidebar__img__2">
                          <img
                            loading="lazy"
                            src={course.author[0].img}
                            alt="blog"
                            style={{width: "50px", borderRadius: "50%"}}
                          />
                        </div>

                        <div className="tab__instructor__inner">
                          <div className="blogsidebar__name__2">
                            <h5>
                              <a href="#/"> {course.author[0].name}</a>
                            </h5>
                            <p>{course.author[0].role}</p>
                          </div>
                          <div className="blog__sidebar__text__2">
                            <p>
                              {course.author[0].descr}
                            </p>
                          </div>
                          <div className="blogsidbar__icon__2">
                            <ul>
                              <li>
                                <a href="#/">
                                  <FacebookIcon fontSize="small"/>
                                </a>
                              </li>

                              <li>
                                <a href="#/">
                                  <YouTubeIcon fontSize="small"/>
                                </a>
                              </li>
                              <li>
                                <a href="#/">
                                  <InstagramIcon fontSize="small"/>
                                </a>
                              </li>
                              <li>
                                <a href="#/">
                                  <XIcon fontSize="small"/>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="course__list__wraper" data-aos="fade-up">
                <div className="blog__details__heading__2">
                  <h5>Why search Is Important ?</h5>
                </div>
                <div
                  className="aboutarea__list__2 blog__details__list__2"
                  data-aos="fade-up"
                >
                  <ul>
                    <li>
                    <i className="icofont-check"> <CheckIcon/></i>
                      <p>
                        Lorem Ipsum is simply dummying text of the printing
                        andtypesetting industry most of the standard.
                      </p>
                    </li>
                    <li>
                    <i className="icofont-check"> <CheckIcon/></i>
                      <p>
                        Lorem Ipsum is simply dummying text of the printing
                        andtypesetting industry most of the standard.
                      </p>
                    </li>
                    <li>
                    <i className="icofont-check"> <CheckIcon/></i>
                      <p>
                        Lorem Ipsum is simply dummying text of the printing
                        andtypesetting industry most of the standard.
                      </p>
                    </li>
                    <li>
                      <i className="icofont-check"> <CheckIcon/></i>
                      <p>
                        Lorem Ipsum is simply dummying text of the printing
                        andtypesetting industry most of the standard.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4">
            <div className="course__details__sidebar">
              <div className="event__sidebar__wraper" data-aos="fade-up">
                <div
                  className="blogarae__img__2 course__details__img__2"
                  data-aos="fade-up"
                >
                  <img
                    src={course.img}
                    alt="blogdfg"
                  />

                  <div className="registerarea__content course__details__video">
                    <div className="registerarea__video">
                      <div className="video__pop__btn">
                        <a
                          className="video-btn"
                          href="https://www.youtube.com/watch?v=vHdclsdkp28"
                        >
                          {" "}
                          <PlayCircleOutlineIcon fontSize="large" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="event__price__wraper">
                  <div className="event__price">
                    RM{course.price}{" "}
                    {course.prevprice && <del>RM{course.prevprice}</del>}
                  </div>
                  <div className="event__Price__button">
                    {course.discount && <a href="#/">{course.discount} Off</a>}
                  </div>
                </div>

                <div className="course__summery__button">
                  <a className="default__button" href="#/">
                    Add To Cart
                  </a>
                  <a className="default__button default__button--2" href="#/">
                    Buy Now
                  </a>
                  <span>
                    <i className="icofont-ui-rotation"></i>
                    45-Days Money-Back Guarantee
                  </span>
                </div>

                <div className="course__summery__lists">
                  <ul>
                    <li>
                      <div className="course__summery__item">
                        <span className="sb_label">Instructor:</span>
                        <span className="sb_content">
                          <a href="#/">{course.author[0].name}</a>
                        </span>
                      </div>
                    </li>



                    <li>
                      <div className="course__summery__item">
                        <span className="sb_label">Total Duration</span>
                        <span className="sb_content">
                          {course.duration} Hrs
                        </span>
                      </div>
                    </li>

                    <li>
                      <div className="course__summery__item">
                        <span className="sb_label">Enrolled</span>
                        <span className="sb_content">100</span>
                      </div>
                    </li>

                    <li>
                      <div className="course__summery__item">
                        <span className="sb_label">Lectures</span>
                        <span className="sb_content">{course.lectures}</span>
                      </div>
                    </li>

                    <li>
                      <div className="course__summery__item">
                        <span className="sb_label">Skill Level</span>
                        <span className="sb_content">{course.level}</span>
                      </div>
                    </li>

                    <li>
                      <div className="course__summery__item">
                        <span className="sb_label">Language</span>
                        <span className="sb_content">{course.language}</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="course__summery__button">
                  <p>More inquery about course.</p>
                  <a
                    className="default__button default__button--3"
                    href="tel:+60182765927"
                  >
                    <i className="icofont-phone"></i> 0 182 765 927
                  </a>
                </div>
              </div>

              <div
                className="blogsidebar__content__wraper__2"
                data-aos="fade-up"
              >
                <h4 className="sidebar__title">Populer Course</h4>
                <ul className="course__details__populer__list">
                  {randomPosts.map((e) => (
                    <li key={e.id}>
                      <div
                        className="course__details__populer__img"
                        style={{ maxWidth: "100px" }}
                      >
                        <img loading="lazy" src={e.img} alt="populer" />
                      </div>
                      <div className="course__details__populer__content">
                        <span>RM{e.price}</span>
                        <h6
                          style={{
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                            textDecoration: "none",
                          }}
                        >
                          <Link to={`/course_detailed/${e.id}`}>{e.title}</Link>
                        </h6>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              <div
                className="blogsidebar__content__wraper__2"
                data-aos="fade-up"
              >
                <h4 className="sidebar__title">Get in Touch</h4>
                <div className="get__touch__input">
                  <input type="text" placeholder="Enter name*" required />
                  <input type="text" placeholder="Enter your mail*" required />
                  <input type="text" placeholder="Massage*" required />
                  <a className="default__button" href="#/">
                    SEND MASSAGE
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
