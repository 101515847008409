import React from 'react'
import HomeCourse from '../courses/Home-Course';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';


const Services = () => {


  return (
    <div>
    <div className="populerarea__2 sp_top_100 sp_bottom_50" style={{margin: "100px 0"}}>
    <div className="container">
        <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 about__wrap__content" data-aos="fade-up">
                <div className="service__animate__shape__1">
                    <img loading="lazy" src="img/service/service__shape__1.png" alt="" />
                </div>
                <div className="populerarea__content__wraper__2">
                    <div className="section__title ">
                        <div className="section__title__button">
                            <div className="default__small__button">Services</div>
                        </div>
                        <div className="section__title__heading">
                            <h2>Provide It &amp; Tech<br />Courses For You</h2>
                        </div>
                    </div>
                    <div className="populerarea__content__2">
                        <p className="populerarea__para__2">Construction is a general term meaning the art and science to form systems organizations, and comes from Latin Construction is a organizations, and comes from Latin construction and Old</p>
                    </div>
                    <div className="populerarea__button__2">
                        <a className="default__button" href="/services_we_provide">Explore More
                            <i className="icofont-long-arrow-right"><ArrowRightAltIcon/></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 service__wrap__content">
                <div className="service__animate__shape__2">
                    <img loading="lazy" src="img/service/service__shape__bg__1.png" alt="" />
                </div>
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12" data-aos="fade-up">
                        <div className="single__service">
                            <div className="service__img">
                                <img loading="lazy" src="assets/images/operations.png" alt="" />
                            </div>
                            <div className="service__content service__content__2">
                                <h3>Web Designing</h3>
                                <p>Construction is a general term the art and science to form </p>
                            </div>
                            <div className="service__button">
                                <a href="/services_we_provide">View Subject
                                    <i className="icofont-long-arrow-right"><ArrowRightAltIcon/></i>
                                </a>
                            </div>
                            <div className="service__small__img">
                                <svg className="icon__hover__img" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z" stroke="#FFB31F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </div>
                        </div>

                        {/* More single_service components */}
                        <div className="single__service">
                            <div className="service__img">
                                <img loading="lazy" src="assets/images/backend.png" alt="" />
                            </div>
                            <div className="service__content service__content__2">
                                <h3>Basic Computer Course</h3>
                                <p>Construction is a general term the art and science to form </p>
                            </div>
                            <div className="service__button">
                                <a href="/services_we_provide">
                                  View Subject
                                    <i className="icofont-long-arrow-right"><ArrowRightAltIcon/></i>
                                </a>
                            </div>
                            <div className="service__small__img">
                                <svg className="icon__hover__img" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z" stroke="#FFB31F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    {/* More columns with single_service components */}
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12" data-aos="fade-up">
                        <div className="single__service">
                            <div className="service__img">
                                <img loading="lazy" src="assets/images/ui-design.png" alt="" />
                            </div>
                            <div className="service__content service__content__2">
                                <h3>UX/UI Design</h3>
                                <p>Construction is a general term the art and science to form </p>
                            </div>
                            <div className="service__button">
                                <a href="/services_we_provide">View Subject
                                    <i className="icofont-long-arrow-right"><ArrowRightAltIcon/></i>
                                </a>
                            </div>
                            <div className="service__small__img">
                                <svg className="icon__hover__img" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z" stroke="#FFB31F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </div>
                        </div>

                        {/* More single_service components */}
                        <div className="single__service">
                            <div className="service__img">
                                <img loading="lazy" src="assets/images/data_821634.png" alt="" />
                            </div>
                            <div className="service__content service__content__2">
                                <h3>Data & Tech</h3>
                                <p>Construction is a general term the art and science to form </p>
                            </div>
                            <div className="service__button">
                                <a href="/services_we_provide">View Subject
                                    <i className="icofont-long-arrow-right"><ArrowRightAltIcon/></i>
                                </a>
                            </div>
                            <div className="service__small__img">
                                <svg className="icon__hover__img" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z" stroke="#FFB31F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>
<HomeCourse/>
</div>
  
  )
}

export default Services
