import React, { useState, useEffect } from "react";
import CourseList from "../../data/data.json";
import YoutubeSearchedForIcon from "@mui/icons-material/YoutubeSearchedFor";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link } from "react-router-dom";

const CourseAll = () => {
  const [value, setValue] = useState("");
  const [displayCount, setDisplayCount] = useState(9);
  const [displayedCourses, setDisplayedCourses] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [showSkillLevel, setShowSkillLevel] = useState(true);
  const [showSearch, setShowSearch] = useState(true);
  const [showLanguage, setShowLanguage] = useState(false);
  const [showCategory, setShowCategory] = useState(false);

  const [sortBy, setSortBy] = useState("");
  const [sortByLiked, setSortByLiked] = useState(false);
  const [likedCourses, setLikedCourses] = useState([]);

  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [filteredCourses, setFilteredCourses] = useState([]);
  const [filteredCoursesByCategory, setFilteredCoursesByCategory] = useState([]);
  const [categories, setCategories] = useState([]);
  const resultsPerPage = 9;

  const totalPages = Math.ceil(totalResults / resultsPerPage);

  const levelCounts = CourseList.reduce((counts, item) => {
    counts[item.level] = (counts[item.level] || 0) + 1;
    return counts;
  }, {});

  useEffect(() => {
    const initialCourses = CourseList.slice(0, displayCount);
    setDisplayedCourses(initialCourses);
    setTotalResults(CourseList.length);
  }, [displayCount]);

  useEffect(() => {
    let filteredCourses = CourseList.filter(
      (course) =>
        course.title.toLowerCase().includes(value.toLowerCase()) &&
        (selectedLevel.length === 0 || selectedLevel.includes(course.level)) &&
        (selectedLanguages.length === 0 ||
          selectedLanguages.includes(course.language)) &&
        (selectedCategories.length === 0 ||
          selectedCategories.includes(course.category))
    );

    if (sortByLiked) {
      filteredCourses = filteredCourses.filter((course) =>
        likedCourses.includes(course.id)
      );
    }

    const startIndex = (currentPage - 1) * resultsPerPage;
    const endIndex = startIndex + resultsPerPage;
    setDisplayedCourses(filteredCourses.slice(startIndex, endIndex));
    setTotalResults(filteredCourses.length);
  }, [
    value,
    displayCount,
    selectedLevel,
    likedCourses,
    sortByLiked,
    selectedLanguages,
    selectedCategories,
    CourseList,
    currentPage,
  ]);

  useEffect(() => {
    if (selectedLanguages.length === 0) {
      setFilteredCourses(CourseList);
    } else {
      const filtered = CourseList.filter((course) =>
        selectedLanguages.includes(course.language)
      );
      setFilteredCourses(filtered);
    }
  }, [selectedLanguages, CourseList]);

  useEffect(() => {
    const uniqueCategories = CourseList.reduce((acc, course) => {
      if (!acc.includes(course.category)) {
        acc.push(course.category);
      }
      return acc;
    }, []);
    setCategories(uniqueCategories);
  }, []);

  useEffect(() => {
    if (selectedCategories.length === 0) {
      setFilteredCoursesByCategory(CourseList);
    } else {
      const filtered = CourseList.filter((course) =>
        selectedCategories.includes(course.category)
      );
      setFilteredCoursesByCategory(filtered);
    }
  }, [selectedCategories, CourseList]);



  const applySort = (courses) => {
    if (sortBy === "lowToHigh") {
      return [...courses].sort((a, b) => a.price - b.price);
    } else if (sortBy === "highToLow") {
      return [...courses].sort((a, b) => b.price - a.price);
    } else if (sortBy === "duration") {
      return [...courses].sort((a, b) => a.duration - b.duration);
    } else if (sortBy === "levels") {
      const levelsOrder = [
        "Beginner",
        "Intermediate",
        "Advanced",
        "Expert",
        "All levels",
      ];
      return [...courses].sort(
        (a, b) => levelsOrder.indexOf(a.level) - levelsOrder.indexOf(b.level)
      );
    } else {
      return courses;
    }
  };

  const languages = CourseList.reduce((uniqueLanguages, course) => {
    if (!uniqueLanguages.includes(course.language)) {
      uniqueLanguages.push(course.language);
    }
    return uniqueLanguages;
  }, []);




  const handleSortChange = (e) => {
    if (e.target.value === "1") {
      setSortByLiked(true);
    } else {
      setSortByLiked(false);
    }

    setSortBy(e.target.value);
  };
  

  const handleClearFilters = () => {
    setValue("");
    setSelectedLevel([]);
    setSelectedLanguages([]);
    setSelectedCategories([]);
    setSortByLiked(false);
    setSortBy("");
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const startIndex = (page - 1) * resultsPerPage;
    const endIndex = startIndex + resultsPerPage;
    setDisplayedCourses(CourseList.slice(startIndex, endIndex));
  };

  const handleLevelFilter = (level) => {
    if (selectedLevel.includes(level)) {
      setSelectedLevel(selectedLevel.filter((item) => item !== level));
    } else {
      setSelectedLevel([...selectedLevel, level]);
    }
    setCurrentPage(1);
  };

  const handleLanguageSelect = (language) => {
    if (selectedLanguages.includes(language)) {
      setSelectedLanguages(
        selectedLanguages.filter((item) => item !== language)
      );
    } else {
      setSelectedLanguages([...selectedLanguages, language]);
    }
  };

  const handleCategorySelect = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(
        selectedCategories.filter((item) => item !== category)
      );
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const handleLike = (id) => {
    if (likedCourses.includes(id)) {
      setLikedCourses(likedCourses.filter((courseId) => courseId !== id));
    } else {
      setLikedCourses([...likedCourses, id]);
    }
  };

  const firstResultNumber = (currentPage - 1) * resultsPerPage + 1;
  const lastResultNumber = Math.min(currentPage * resultsPerPage, totalResults);

  return (
    <div className="coursearea sp_top_100 sp_bottom_100" style={{margin: "50px 0"}}>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="course__text__wraper" data-aos="fade-up">
              <div className="course__text">
                <p>
                  Showing {Math.min(firstResultNumber, totalResults)}–
                  {Math.min(lastResultNumber, totalResults)} of {totalResults}{" "}
                  Results
                </p>
              </div>
              <div className="course__icon">
                <ul
                  className="nav property__team__tap"
                  id="myTab"
                  role="tablist"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="course__categories__list">
                    {(selectedLanguages.length > 0 ||
                      selectedLevel.length > 0 ||
                      selectedCategories.length > 0 ||
                      sortByLiked ||
                      sortBy !== "") && (
                      <button
                        onClick={handleClearFilters}
                        style={{
                          border: "none",
                          fontWeight: "500",
                          color: "#042c76",
                          backgroundColor: "transparent",
                        }}
                      >
                        Clear Filters
                      </button>
                    )}
                  </div>
                  <li className="short__by__new">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={handleSortChange}
                    >
                      <option value="0">Sort by New</option>
                      <option value="1">Sort by Loved</option>
                      <option value="lowToHigh">Low to High (Price)</option>
                      <option value="highToLow">High to Low (Price)</option>
                    </select>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {sortByLiked && likedCourses.length === 0 && (
            <div className="col-xl-12">
              <div
                className="no-liked-courses-message"
                style={{ textAlign: "center", marginTop: "20px" }}
              >
                <h4 style={{ color: "#000000", textAlign: "end" }}>
                  You haven't liked any courses yet.
                </h4>
              </div>
            </div>
          )}

          <div className="col-xl-3 col-lg-3 col-md-4 col-12">
            <div className="course__sidebar__wraper" data-aos="fade-up">
              <div
                className="course__heading"
                onClick={() => setShowSearch(!showSearch)}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <h5>Search here</h5>
                {showSearch ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </div>
              {showSearch && (
                <div className="course__input">
                  <input
                    type="text"
                    placeholder="Search product"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                  <div className="search__button">
                    <button>
                      <YoutubeSearchedForIcon />
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="course__sidebar__wraper" data-aos="fade-up">
              <div className="course__categories__list">
                <div
                  className="course__heading"
                  onClick={() => setShowSkillLevel(!showSkillLevel)}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                >
                  <h5>Skill Level</h5>
                  {showSkillLevel ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </div>

                {showSkillLevel && (
                  <ul>
                    {Object.entries(levelCounts).map(([level, count]) => (
                      <li key={level}>
                        <a
                          href="#/"
                          onClick={() => handleLevelFilter(level)}
                          className={
                            selectedLevel.includes(level) ? "active" : ""
                          }
                          style={
                            selectedLevel.includes(level)
                              ? { backgroundColor: "#042c76", color: "#fff" }
                              : {}
                          }
                        >
                          {level}
                          <span>{count}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <div className="course__sidebar__wraper" data-aos="fade-up">
              <div
                className="course__heading"
                onClick={() => setShowLanguage(!showLanguage)}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <h5>Languages</h5>
                {showLanguage ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </div>
              {showLanguage && (
                <div className="course__tag__list">
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    {languages.map((language, index) => (
                      <li key={index} style={{ marginBottom: "5px" }}>
                        <a
                          href="#/"
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <input
                            type="checkbox"
                            id={language}
                            value={language}
                            checked={selectedLanguages.includes(language)}
                            onChange={() => handleLanguageSelect(language)}
                            style={{ marginRight: "5px" }}
                          />
                          <label htmlFor={language}>{language}</label>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <div className="course__sidebar__wraper" data-aos="fade-up">
              <div
                className="course__heading"
                onClick={() => setShowCategory(!showCategory)}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <h5>Categories</h5>
                {showCategory ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </div>
              {showCategory && (
                <div className="course__tag__list">
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    {categories.map((category, index) => (
                      <li key={index} style={{ marginBottom: "5px" }}>
                        <a
                          href="#/"
                          style={{ textDecoration: "none", color: "inherit" }}
                          onClick={() => handleCategorySelect(category)}
                        >
                          <input
                            type="checkbox"
                            id={category}
                            checked={selectedCategories.includes(category)}
                            onChange={() => handleCategorySelect(category)}
                            style={{ marginRight: "5px" }}
                          />
                          <label htmlFor={category}>{category}</label>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="col-xl-9 col-lg-9 col-md-8 col-12">
            <div
              className="tab-content tab__content__wrapper with__sidebar__content"
              id="myTabContent"
            >
              <div
                className="tab-pane fade  active show"
                id="projects__one"
                role="tabpanel"
                aria-labelledby="projects__one"
              >
                <div className="row">
                  {applySort(displayedCourses).map((e, index) => (
                    <div
                      key={e.id}
                      className="col-xl-4 col-lg-6 col-md-12 col-sm-6 col-12"
                      data-aos="fade-up"
                    >
                      <div className="gridarea__wraper gridarea__wraper__2">
                        <div className="gridarea__img">
                          <Link to={`/course_detailed/${e.id}`}>
                            <img loading="lazy" src={e.img} alt="grid" />
                          </Link>
                          <div className="gridarea__small__button">
                            <div className="grid__badge">{e.lang}</div>
                          </div>
                          <div className="gridarea__small__icon">
                            <button
                              onClick={() => handleLike(e.id)}
                              className="icofont-heart-alt"
                              style={{
                                border: "none",
                                backgroundColor: "#042c76",
                                color: "#fff",
                                padding: "2px",
                                borderRadius: "5px",
                              }}
                            >
                              {likedCourses.includes(e.id) ? (
                                <FavoriteIcon style={{ color: "red" }} />
                              ) : (
                                <FavoriteIcon />
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="gridarea__content">
                          <div className="gridarea__list">
                            <ul>
                              <li>
                                <LibraryBooksIcon
                                  fontSize=""
                                  style={{ color: "#042c76" }}
                                />{" "}
                                {e.lectures}
                              </li>
                              <li>
                                <WatchLaterIcon
                                  fontSize=""
                                  style={{ color: "#042c76" }}
                                />{" "}
                                {e.duration} hours
                              </li>
                            </ul>
                          </div>
                          <div
                            className="gridarea__heading"
                            style={{
                              display: "-webkit-box",
                              overflow: "hidden",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                              textDecoration: "none",
                            }}
                          >
                            <h3><Link to={`/course_detailed/${e.id}`}>{e.title}...</Link></h3>
                          </div>
                          <div
                            className="gridarea__price"
                            style={{ padding: "10px 0" }}
                          >
                            RM{e.price}{" "}
                            {e.prevprice ? <del>/ RM{e.prevprice}</del> : ""}
                          </div>
                          <div className="gridarea__bottom">
                            <a href="#/">
                              <div className="gridarea__small__img">
                                <img
                                  loading="lazy"
                                  src={e.author[0].img}
                                  alt="grid"
                                />
                                <div className="gridarea__small__content">
                                  <h6>{e.author[0].name}</h6>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="main__pagination__wrapper" data-aos="fade-up">
              {totalPages > 1 && (
                <ul className="main__page__pagination">
                  {currentPage !== 1 && (
                    <li>
                      <a
                        className={currentPage === 1 ? "disable" : ""}
                        href="#/"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <KeyboardDoubleArrowLeftIcon />
                      </a>
                    </li>
                  )}
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li key={index}>
                      <a
                        className={currentPage === index + 1 ? "active" : ""}
                        href="#/"
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </a>
                    </li>
                  ))}
                  {currentPage !== totalPages && (
                    <li>
                      <a
                        className={currentPage === totalPages ? "disable" : ""}
                        href="#/"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <KeyboardDoubleArrowRightIcon />
                      </a>
                    </li>
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseAll;
