import React from 'react';
import data from '../../data/data.json'
import { Link } from 'react-router-dom';

const Footer = () => {

    const shuffledArray = data.sort(() => Math.random() - 0.5);
    const randomPosts = shuffledArray.slice(0, 3);
    return (
        <div className="footerarea footerarea__image" style={{backgroundImage: 'url(img/footer/footer_bg_ai.png)'}}>
            <div className="container">
                <div className="footerarea__wrapper footerarea__wrapper__2">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12" data-aos="fade-up">
                            <div className="footerarea__inner footerarea__about__us">
                                <div className="footerarea__heading">
                                    <h3>About us</h3>
                                </div>
                                <div className="footerarea__content">
                                    <p>Welcome to the Online Learning Center
</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6" data-aos="fade-up">
                            <div className="footerarea__inner">
                                <div className="footerarea__heading">
                                    <h3>Useful Links</h3>
                                </div>
                                <div className="footerarea__list">
                                    <ul>
                                        <li>
                                            <a href="/aboutus">About Us</a>
                                        </li>
                                        <li>
                                            <a href="/services">Services</a>
                                        </li>
                                        <li>
                                            <a href="/contact">Contact</a>
                                        </li>
                                        <li>
                                            <a href="/allcourses">All Courses</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6" data-aos="fade-up">
                            <div className="footerarea__inner footerarea__padding__left">
                                <div className="footerarea__heading">
                                    <h3>Course</h3>
                                </div>
                                <div className="footerarea__list">
                                    <ul>
                                        <li>
                                            <a href="/allcourses">FrontEnd</a>
                                        </li>
                                        <li>
                                            <a href="/allcourses">UX/UI Design</a>
                                        </li>
                                        <li>
                                            <a href="/allcourses">Big Data</a>
                                        </li>
                                        <li>
                                            <a href="/allcourses">Data & Tech</a>
                                        </li>
                                        <li>
                                            <a href="/allcourses">BackEnd</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12" data-aos="fade-up">
                            <div className="footerarea__right__wrapper footerarea__inner">
                                <div className="footerarea__heading">
                                    <h3>Recent Post</h3>
                                </div>
                                <div className="footerarea__right__list">
                                    <ul>
                                        {randomPosts.map((post) => (
                                        <li key={post.id}>
                                            <Link to={`/course_detailed/${post.id}`} style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                                <div className="footerarea__right__img">
                                                    <img loading="lazy" style={{width: "60px", borderRadius: "10px"}} src={post.img} alt="footerphoto" />
                                                </div>
                                                <div className="footerarea__right__content" style={{marginLeft: "15px"}}>
                                                    <span style={{color: "#fffff"}}>RM{post.price}</span>
                                                    <h6 style={{color: "white",                             display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                            textDecoration: "none",}}>{post.title}</h6>
                                                </div>
                                            </Link>
                                        </li>                                            
                                        ))}
                                        
                                        
                                    </ul>
                                </div>
                            </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
