import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div id="contact" className="contact-us section">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <div className="section-heading wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
            <h6>Contact Us</h6>
            <h4>Get In Touch With Us <em>Now</em></h4>
            <div className="line-dec"></div>
          </div>
        </div>
        <div className="col-lg-12 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
          <form id="contact" action="" method="post">
            <div className="row">
              <div className="col-lg-12">
                <div className="contact-dec">
                  <img src="assets/images/contact-dec-v3.png" alt=""/>
                </div>
              </div>
              <div className="col-lg-5">
                <div id="map">
                  <iframe src="https://maps.google.com/maps?q=Kelana%20Mall&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=&amp;output=embed" width="100%" title='video' height="636px" frameBorder="0" style={{border:"0"}} allowFullScreen></iframe>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="fill-form">
                  <div className="row">

                    
                    <div className="col-lg-4 contact-icons">
                      <div className="info-post">
                        <div className="icon">
                          <img src="assets/images/mobile.png" alt=""/>
                          <a href="/#" className='contacts-links'>018-27-65-927</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 contact-icons">
                      <div className="info-post">
                        <div className="icon">
                          <img src="assets/images/gmail.png" alt=""/>
                          <a href="/#" className='contacts-links'>@gmail.com</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 contact-icons">
                      <div className="info-post">
                        <div className="icon">
                          <img src="assets/images/map.png" alt=""/>
                          <a href="/#" className='contacts-links'>Kelana Mall</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <fieldset>
                        <input type="text" name="name" id="name" placeholder="Name" autoComplete="on" required/>
                      </fieldset>
                      <fieldset>
                        <input type="email" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Your Email" required/>
                      </fieldset>
                      <fieldset>
                        <input type="number" name="number" id="number" placeholder="Number" autoComplete="on" required/>
                      </fieldset>
                    </div>
                    <div className="col-lg-6">
                      <fieldset>
                        <textarea name="message" type="text" className="form-control" id="message" placeholder="Message" required></textarea>  
                      </fieldset>
                    </div>
                    <div className="col-lg-12">
                      <fieldset>
                        <button type="submit" id="form-submit" className="main-button ">Send Message Now</button>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Contact
