import React from 'react'
import './InnerServices.scss';

const InnerServices = () => {
  return (
    <div style={{width: "80%",textAlign: "center", margin: "150px auto"}}>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" data-aos="fade-up">
            <div className="populerarea__heading heading__underline">
              <div className="default__small__button">Services</div>
              <h2>Website Design and Development, Digital Marketing, Mobile App Development in Arambagh</h2>
            </div>
            <p style={{width: "100%",textAlign: "start", borderLeft: "3px solid #042c76", padding: "25px 15px"}} className='innerservicetext'>Tech Vision Soft is a tailored, experienced website design company in Arambagh, India that is committed to enhancing customer satisfaction. Our company is based in Arambagh and has more than a decade of experience in providing creative and attractive website designs to thousands of customers. We have a lot of experience in website design like eCommerce (online shopping portal), school, college, NGO, business, real estate, health, tour and travel, hotel and government portal etc. Our our professional top quality website designer is always ready to make your imagination a reality To give.</p>
          </div>



<div className='col-xl-12 col-lg-12 col-md-12 col-sm-12' style={{margin: "80px 0 0 0"}}>
          <div className="blog-card" >
            <div className="meta">
            <div className="photo" style={{backgroundImage: 'url(https://img-c.udemycdn.com/course/480x270/3830262_2c3b_3.jpg)'}}></div>
                <ul className="details" style={{display: "flex", flexDirection: "column", alignItems: "self-start", justifyContent: "space-between"}}>
                            <li><a href="#/">- Responsive Web Designing</a></li>
                            <li><a href="#/">- Domain Services</a></li>
                            <li><a href="#/">- e-Commerce Website</a></li>
                            <li><a href="#/">- Digital Marketing</a></li>
                            <li><a href="#/">- SEO Services</a></li>
                            <li><a href="#/">- Domain & Hosting Services</a></li>
                </ul>
            </div>
            <div className="description">
                <h1>Web Designing</h1>
                <h2>Tech Vision Software</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati enim dicta praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.</p>
            </div>
        </div>
        <div className="blog-card alt">
            <div className="meta">
            <div className="photo" style={{backgroundImage: 'url(https://img-b.udemycdn.com/course/480x270/2184976_167e_3.jpg)'}}></div>
                <ul className="details" style={{display: "flex", flexDirection: "column", alignItems: "self-start", justifyContent: "space-between"}}>
                            <li><a href="#/">- Website Development</a></li>
                            <li><a href="#/">- Mobile App Development</a></li>
                            <li><a href="#/">- Database as a Service</a></li>
                            <li><a href="#/">- AZURE Development</a></li>
                            <li><a href="#/">- DevOps & Automation</a></li>
                            <li><a href="#/">- Networking as a Service</a></li>
                </ul>
            </div>
            <div className="description">
                <h1>Basic Computer Course</h1>
                <h2>Tech Vision Software</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati enim dicta praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.</p>
            </div>
        </div>

        <div className="blog-card">
            <div className="meta">
                <div className="photo" style={{backgroundImage: 'url(https://img-c.udemycdn.com/course/480x270/2887266_c696_5.jpg)'}}></div>
                <ul className="details" style={{display: "flex", flexDirection: "column", alignItems: "self-start", justifyContent: "space-between"}}>
                            <li><a href="#/">- Responsive Web Designing</a></li>
                            <li><a href="#/">- Domain Services</a></li>
                            <li><a href="#/">- e-Commerce Website</a></li>
                            <li><a href="#/">- Digital Marketing</a></li>
                            <li><a href="#/">- SEO Services</a></li>
                            <li><a href="#/">- Domain & Hosting Services</a></li>
                </ul>
            </div>
            <div className="description">
                <h1>UX/UI Design</h1>
                <h2>Tech Vision Software</h2>
                <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati enim dicta praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.</p>
            </div>
        </div>
        <div className="blog-card alt">
            <div className="meta">
                <div className="photo" style={{backgroundImage: 'url(https://img-b.udemycdn.com/course/480x270/836376_8b97_4.jpg)', backgroundSize: "cover", backgroundPosition: "center"}}></div>
                <ul className="details" style={{display: "flex", flexDirection: "column", alignItems: "self-start", justifyContent: "space-between"}}>
                            <li><a href="#/">- Website Development</a></li>
                            <li><a href="#/">- Mobile App Development</a></li>
                            <li><a href="#/">- Database as a Service</a></li>
                            <li><a href="#/">- AZURE Development</a></li>
                            <li><a href="#/">- DevOps & Automation</a></li>
                            <li><a href="#/">- Networking as a Service</a></li>
                </ul>
            </div>
            <div className="description">
                <h1>Data & Tech</h1>
                <h2>Tech Vision Software</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati enim dicta praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.</p>
            </div>
        </div>
</div>
    </div>
  )
}

export default InnerServices