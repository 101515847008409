import React from "react";
import "./Underfined.scss";

const Underfined = () => {
  return (
    <div id="main">
      <div className="fof">
        <h1>Error 404</h1>
      </div>
      <div style={{margin: "20px 0"}}>
        <a href="/">
        <button style={{padding: "10px", border: "1px solid #042c76", borderRadius: "20px"}}>Home Page</button>
        </a>
      </div>
    </div>
  );
};

export default Underfined;
