import React, { useState } from "react";
import servicesData from "../../../data/services.json";
import "./Service.scss";

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';

const Servicess = () => {
  const [filter, setFilter] = useState("Web Designing");

  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const togglePopup = () => {
    setIsOpen(true);
  };

  const openDetail = (service) => {
    setSelectedItem(service);
    togglePopup();
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClick = (filter) => {
    setFilter(filter);
  };

  return (
    <div className="gridarea" style={{ margin: "50px 0" }}>
      <div className="container">
        <div className="row">
          <div className="section__title text-center" data-aos="fade-up">
            <div className="section__title__button">
              <div className="default__small__button">Our Services</div>
            </div>
            <div className="section__title__heading heading__underline">
              <h2>
                We help entrepreneurs achieving their goal faster with our
                <br /> Creativity and Excellences
              </h2>
            </div>
          </div>
        </div>

        <div className="row" data-aos="fade-up">
          <div >
            <div className="gridfilter_nav grid__filter gridFilter">
              <button
                className={filter === "Web Designing" ? "active" : ""}
                onClick={() => handleClick("Web Designing")}
              >
                Web Designing
              </button>
              <button
                className={filter === "Web & Software" ? "active" : ""}
                onClick={() => handleClick("Web & Software")}
              >
                Web & Software
              </button>
              <button
                className={filter === "Graphic Designing" ? "active" : ""}
                onClick={() => handleClick("Graphic Designing")}
              >
                Graphic Designing
              </button>
            </div>
          </div>
        </div>

        {/* Repeat the following structure for each grid item */}
        <div className="row grid" data-aos="fade-up">
        {/* Grid Item */}
        {servicesData?.map(
          (service, index) =>
            service.title === filter &&
            service.items.map((item, index) => (
              <div
                key={index}
                className="col-xl-4 col-lg-6 col-sm-12 grid"
                onClick={() => openDetail(item)}
              >
                <div className="single__service">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <div className="service__img">
                      <div className="service__bg__img">
                        <img src={item.icon} alt="" />
                      </div>
                    </div>
                    <div className="service__content">
                      <h3>{item.name}</h3>
                    </div>
                  </div>
                </div>
              </div>
            ))
        )}
      </div>
        {isOpen && (
       <Dialog  open={isOpen}
       onClose={handleClose}
       >
         <div className="content" style={{padding: "50px"}}>
            <div className="container">
              <div className="dots">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>

              <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
  <CloseIcon />
</IconButton>

              <div className="title" style={{textAlign: "center"}}>
                <h1>{selectedItem.name}</h1>
              </div>

              <img src={selectedItem.img} alt={selectedItem.name} />

              <div className="description" style={{textAlign: "center", padding: "25px"}}>
                <p>{selectedItem.description}</p>
              </div>
            </div>
          </div>
        </Dialog>
      )}
      </div>
    </div>
  );
};

export default Servicess;
