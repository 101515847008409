import React, { useRef } from "react";
import Services from "../services/Services";
import Contact from "../contact/Contact";
import AboutUs from "../aboutus/AboutUs";
import Platform from "../platforms/Platform";
import Partners from "../partners/Partners";
import Slider from "react-slick";
import data from "../../data/data.json";
import "../../slick/slick.css";
import "../../slick/slick-theme.css";
import { Link } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";

import Rating from "@mui/material/Rating";
import Provides from "../Provides/Provides";

const Home = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  const shuffledArray = data.sort(() => Math.random() - 0.5);
  const randomPosts = shuffledArray.slice(0, 4);

  return (
    <div
      className="main-banner "
      id="top"
      data-wow-duration="1s"
      data-wow-delay="0.5s"
    >
      <div className="herobannerarea herobannerarea__2 herobannerarea__clms">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
              data-aos="fade-up"
            >
              <div className="herobannerarea__content__wraper">
                <div className="herobannerarea__title">
                  <div className="herobannerarea__small__title">
                    <span>Tech Vision & Software</span>
                  </div>
                  <div className="herobannerarea__title__heading__2 herobannerarea__title__heading__3">
                    <h2>
                      Empower Your Future: Launch with IT Courses Startup!
                    </h2>
                  </div>
                </div>

                <div className="herobannerarea__text herobannerarea__text__2">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing typesetting{" "}
                    <br />
                    industry. Lorem Ipsum has been{" "}
                  </p>
                </div>
                <div className="hreobannerarea__button__2">
                  <Link className="default__button" to="/allcourses">
                    View Courses
                  </Link>
                  <Link
                    className="default__button hreobannerarea__button__3"
                    to="/allcourses"
                  >
                    Explore More
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-8 col-sm-12 col-12"
              data-aos="fade-up"
            >
              <div className="swiper hero__course">
                <div className="swiper-wrapper">
                  <Slider {...settings} ref={sliderRef}>
                    {randomPosts?.map((e) => (
                      <div
                        className="swiper-slide gridarea__wraper gridarea__wraper__2"
                        style={{ marginRight: "20px" }}
						key={e.id}
                      >
                        <div className="gridarea__img">
                          <Link to={`/course_detailed/${e.id}`}>
                            <img loading="lazy" src={e.img} alt="grid" />
                          </Link>
                          <div className="gridarea__small__button">
                            <div className="grid__badge">{e.category}</div>
                          </div>
                          <div className="gridarea__small__icon">
                            <a href="#/">
                              <i className="icofont-heart-alt">
                                <FavoriteIcon />
                              </i>
                            </a>
                          </div>
                        </div>
                        <div className="gridarea__content">
                          <div className="gridarea__list">
                            <ul>
                              <li>
                                <i className="icofont-book-alt"></i>{" "}
                                {e.lectures}
                              </li>
                              <li>
                                <i className="icofont-clock-time"></i>{" "}
                                {e.duration} Hrs
                              </li>
                            </ul>
                          </div>
                          <div className="gridarea__heading">
                            <h3>
                              <Link to={`/course_detailed/${e.id}`}>
                                {e.title}
                              </Link>
                            </h3>
                          </div>
                          <p
                            style={{
                              display: "-webkit-box",
                              overflow: "hidden",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                              textDecoration: "none",
                            }}
                          >
                            {e.subtext}...
                          </p>
                          <div className="gridarea__price">
                            RM{e.price}{" "}
                            {e.prevprice ? <del>/ {e.prevprice}</del> : ""}
                            <span>
                              {" "}
                              <del className="del__2">Free</del>
                            </span>
                          </div>
                          <div className="gridarea__bottom">
                            <Link to={`/course_detailed/${e.id}`}>
                              <div className="gridarea__small__img">
                                <img
                                  loading="lazy"
                                  src="assets/img/grid_small_1.jpg"
                                  alt="grid"
                                />
                                <div className="gridarea__small__content">
                                  <h6>{e.author[0].name}</h6>
                                </div>
                              </div>
                            </Link>

                            <div className="gridarea__star">
                              <Rating
                                name="size-medium"
                                defaultValue={2}
                                color="#fff"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className="slider__controls__wrap slider__controls__pagination slider__controls__arrows">
                  <div className="swiper-pagination"></div>
                </div>
              </div>
            </div>

            <div className="herobannerarea__icon__2">
              <img
                loading="lazy"
                className="herobanner__common__img herobanner__img__1"
                style={{ width: "50px" }}
                src="assets/img/herobanner/herobanner__1.png"
                alt=""
              />
              <img
                loading="lazy"
                className="herobanner__common__img herobanner__img__2"
                style={{ width: "50px" }}
                src="assets/img/herobanner/herobanner__2.png"
                alt=""
              />
              <img
                loading="lazy"
                className="herobanner__common__img herobanner__img__3"
                style={{ width: "50px" }}
                src="assets/img/herobanner/herobanner__3.png"
                alt=""
              />
              <img
                loading="lazy"
                className="herobanner__common__img herobanner__img__5"
                style={{ width: "50px" }}
                src="assets/img/herobanner/herobanner__5.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <Provides />
      <AboutUs />
      <Services />
      <Platform />
      <Contact />
      <Partners />
    </div>
  );
};

export default Home;
