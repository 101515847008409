import React from 'react'
import CheckIcon from '@mui/icons-material/Check';

const AboutUs = () => {
    const [progress, setProgress] = React.useState(10);

    React.useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 90 ? 0 : prevProgress + 10));
      }, 800);
      return () => {
        clearInterval(timer);
      };
    }, []);
    
  return (
    <div class="aboutarea__2 sp_top_100" style={{margin: "50px 0"}}>
    <div class="container">
        <div class="row" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                <div class="about__right__wraper__2">
                    <div class="educationarea__img" data-tilt="">
                        <img loading="lazy" class="aboutarea__2__img__1" style={{width: "80%"}} src="https://img.freepik.com/free-photo/image-asian-girl-video-call-waving-hand-her-video-chat-laptop-smiling-student-tea_1258-84053.jpg?w=2000&t=st=1708678030~exp=1708678630~hmac=3c950ea095944614ad44314b99ed83da7125e6a3901a8193bbdf23f5854fa1e5" alt="education"/>
                    </div>
                    <div class="aboutarea__2__text">
                        <div class="aboutarea__counter">
                            <span class="counter">25</span> +
                        </div>

                        <p>YEARS EXPERIENCE JUST ACHIVED</p>


                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" data-aos="fade-up">

                <div class="aboutarea__content__wraper">
                    <div class="aboutarea__button">
                        <div class="default__small__button">About Us</div>
                    </div>
                    <div class="aboutarea__headding heading__underline">
                        <h2>Welcome to the <span>Online</span> Learning Center</h2>
                    </div>
                    <div class="aboutarea__para aboutarea__para__2">
                        <p>25+Contrary to popular belief, Lorem Ipsum is not simply random text roots in a piece of classical Latin literature from 45 BC</p>
                    </div>
                    <div class="aboutarea__list__2">
                        <ul>
                            <li>
                                <i class="icofont-check"><CheckIcon fontSize='small'/></i> Lorem Ipsum is simply dummy
                            </li>

                            <li>
                                <i class="icofont-check"><CheckIcon fontSize='small'/></i> Explore a variety of fresh educational teach
                            </li>

                            <li>
                                <i class="icofont-check"><CheckIcon fontSize='small'/></i> Lorem Ipsum is simply dummy text of
                            </li>
                        </ul>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
  )
}

export default AboutUs
