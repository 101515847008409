import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/header/Header';
import TopBAr from './components/topbar/TopBAr';
import Home from './pages/home/Home';
import Services from './pages/services/Services';
import Contact from './pages/contact/Contact';
import AboutUs from './pages/aboutus/AboutUs';
import Underfined from './pages/underfined/Underfined';
import Course from './pages/courses/Course';
import CourseAll from './pages/courses/CourseAll';
import Servicess from './pages/Provides/services/Services';
import TrainingCourses from './pages/Provides/trainingcourses/TrainingCourses';
import InnerServices from './pages/services/innerservices/InnerServices';
import Footer from './components/footer/Footer';

function App() {
  const [loader, setLoader] = useState(true);
    const spinner = document.getElementById('spinner');


 
      if(spinner){
        setTimeout(() => {
          spinner.style.display="none";
          setLoader(false)
        }, 3000)
      }


      

  return (
    !loader && <div className="App">
      <TopBAr/>
      <Header/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/services' element={<Services/>}/>
        <Route path='/aboutus' element={<AboutUs/>}/>
        <Route path='/contact' element={<Contact/>}/>


        <Route path='/allcourses' element={<CourseAll/>} />     
        <Route path='/course_detailed/:id' element={<Course/>} />

        <Route path='/our_service' element={<Servicess/>} />
        <Route path='/training_courses' element={<TrainingCourses/>} />

        <Route path='/*' element={<Underfined/>} />
		<Route path='/services_we_provide' element={<InnerServices/>}/>
			
      </Routes>
 <Footer/>
    </div>
  );
}

export default App;
