import React from 'react'
import './Header.css';
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';

import HomeIcon from '@mui/icons-material/Home';
import GroupsIcon from '@mui/icons-material/Groups';
import TerminalIcon from '@mui/icons-material/Terminal';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import { Link } from "react-router-dom";

const Header = (props) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { window } = props;
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };


  const handleClose = () => {
    setMobileOpen(false);
  };


  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(true);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle}

          sx={{ textAlign: "center", width: "300px" }}>
<List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Tech Vision & Software
        </ListSubheader>
      }
    >
      <Link to={'/'} className='header-links'>
      
      <ListItemButton>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
      </Link>
      <Link to={'/aboutus'} className='header-links'>
        
      <ListItemButton>
        <ListItemIcon >
          <GroupsIcon />
        </ListItemIcon>
        <ListItemText primary="About" />
      </ListItemButton>
      </Link>
      <Link to={'/services'} className='header-links'>
      <ListItemButton>
        <ListItemIcon>
          <TerminalIcon />
        </ListItemIcon>
        <ListItemText primary="Services" />
      </ListItemButton>
      </Link>
      <Link to={'/contact'} className='header-links'>
      <ListItemButton>
        <ListItemIcon>
          <PermPhoneMsgIcon />
        </ListItemIcon>
        <ListItemText primary="Contact" />
      </ListItemButton>
      </Link>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary="Example" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Menu" />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
    </Box>
      );

  return (
    <header className="header-area header-sticky wow slideInDown" data-wow-duration="0.75s" data-wow-delay="0s">
    <div className="container" 
>
      <div className="row">
        <div className="col-12">
          <nav className="main-nav">
            <a href="/" className="logo">
              <img src="assets/images/logo-color.png" alt="" style={{width: "300px", padding: "0 0 0 0"}}/>
            </a>
            <ul className="nav"
>
              <li className="scroll-to-section"><Link to="/" className="active">Home</Link></li>
              <li className="scroll-to-section"><Link to="aboutus">About</Link></li>
              <li className="scroll-to-section"><Link to="services">Services</Link></li>
              <li className="scroll-to-section"><Link to="contact">Contact</Link></li> 
              <li className="scroll-to-section"><div className="border-first-button"><Link to="contact">Free Quote</Link></div></li> 
            </ul>        
            <a className='menu-trigger' onClick={handleDrawerToggle}>
                <span>Menu</span>
            </a>
          </nav>
        </div>
      </div>
    </div>
    <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          anchor="right"
          onClose={handleClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { sm: "block", md: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box" },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
  </header>
  )
}

Header.propTypes = {
  window: PropTypes.func,
};

export default Header
