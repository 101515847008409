import React from 'react'

const TopBAr = () => {
  return (
    <div className="pre-header">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 col-sm-8 col-7">
          <ul className="info">
            <li><a href="/#"><i className="fa fa-envelope"></i>gmail.com</a></li>
            <li><a href="/#"><i className="fa fa-phone"></i>0182765927</a></li>
          </ul>
        </div>
        <div className="col-lg-3 col-sm-4 col-4">
          <ul className="social-media">
            <li><a href="/#"><i className="fa fa-facebook"></i></a></li>
            <li><a href="/#"><i className="fa fa-behance"></i></a></li>
            <li><a href="/#"><i className="fa fa-twitter"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  )
}

export default TopBAr
