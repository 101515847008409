import React from 'react';
import { Link } from 'react-router-dom';

function Provides() {
  return (
    <div className="populerarea sp_top_80 sp_bottom_50">
      <div className="container">
        <div className="row populerarea__wraper">
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6" data-aos="fade-up">
            <div className="populerarea__heading heading__underline">
              <div className="default__small__button">Services</div>
              <h2>What type of services we can provide?</h2>
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6" data-aos="fade-up">
            <div className="populerarea__button">
              <a className="default__button" href="#/">All Categories
                <i className="icofont-long-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="row" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12" data-aos="fade-up">
        <a href="/our_service">
      <div className="single__service" href={"/our-service"}>
        <div className="service__img">
          <div className="service__bg__img">
          <img src="assets/img/service.png" alt="" />
          </div>
        </div>
        <div className="service__content">
          <h3>Services</h3>
          <p>We help entrepreneurs achieving their goal faster with our creativity and Excellences.</p>
        </div>
        <div className="service__small__img">
        </div>
      </div>
      </a>
    </div>
   
    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12" data-aos="fade-up">
      <a href="/training_courses">     
      <div className="single__service">
        <div className="service__img">
          <div className="service__bg__img">
          <img src="assets/img/social.png" alt="" />
          </div>
        </div>
        <div className="service__content">
          <h3>Training Courses</h3>
          <p>TechVision & Software offers a wide range of IT career courses. Select a category below & find the best course for you.</p>
        </div>
        <div className="service__small__img">
        </div>
      </div>
      </a> 
    </div>
 
        </div>
      </div>
    </div>
  );
}


export default Provides;
